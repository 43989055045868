
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'BrandLogo',
  props: {
    tailwindClass: { type: String, default: '' },
    orgName: { type: String, required: true },
    logoType: { type: String, default: 'primary' },
  },
  computed: {
    sourceLogo() {
      try {
        return require(`@/assets/logos/${this.orgName}-logo-${this.logoType}.svg`)
      } catch {
        return require(`@/assets/logos/${this.orgName}-logo-primary.svg`)
      }
    },
  },
})
