
import AppHeader from '@/components/AppHeader.vue' // @ is an alias to /src
import LoadingOverlay from './components/LoadingOverlay.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  components: {
    LoadingOverlay,
    AppHeader,
  },
  data() {
    return {}
  },
})
